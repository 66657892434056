@charset "utf-8";

// Import Urbanist font
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Customize Bulma
$family-sans-serif: 'Urbanist', sans-serif;
$primary: hsl(229, 53%, 53%);
$navbar-item-color: $primary;
$navbar-item-hover-color: hsl(0, 0%, 21%);

// Import Bulma
@import '../node_modules/bulma/bulma.sass';

h1 {
  font-weight: 900; }

.popup-content {
  max-height: 90vh;
  overflow-y: auto; }
